import type { IDoctor } from '../../EastclinicVueApi';
import doctorInfoSpecialsList from './SpecialsList';
import doctorInfoClinicWorkDescription from './ClinicWorkDescription';
export default function (doctor:IDoctor, size:string) {
  let photo = doctor?.content?.filter(
    (content) => content.typeFile === 'image' && content.type === size)?.[0];
  if (!photo) {
    photo = {
      id: null,
      type: size,
      typeFile: 'image',
      url: 'https://eastclinic.ru/assets/images/photo_soon.png'
    };
  }
  photo.alt = doctorInfoSpecialsList(doctor) + ' ' + doctor?.fullname + ' ' + doctorInfoClinicWorkDescription(doctor);
  return photo;
}
