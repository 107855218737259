import type IDoctorState from '../../interfaces/IDoctorState';
export default function (doctor:IDoctorState) {
  let clinicPart = '';
  if (!doctor) { return clinicPart; }
  if (doctor?.filials) {
    if (doctor.filials[42]) { return 'Ист клиника, прием онлайн'; }
    if (doctor.currentClinic) {
      return 'прием в медицинском центре ' + doctor.currentClinic.full_name;
    }
    clinicPart =
        Object.keys(doctor.filials).length > 1
          ? 'прием в  медицинских центрах '
          : 'прием в медицинском центре ';
    const clinicsTitles =
        doctor?.clinics?.map((clinic) => clinic.full_name) ?? [];
    clinicPart += clinicsTitles.join(', ');
  }
  return clinicPart;
}
